.productcare-container {
    margin: 0 auto;
    width: 90vw;
    position: relative;
    top: 4.5rem;
    margin-bottom: 7rem;
  }
  
  .care {
    margin: 0 auto;
    width: 70%;
    color: black;
    justify-content: center;
  }
  
  .care-header {
    font-size: 2.5rem;
    color: rgb(113, 255, 25);
    border-bottom: 0.1rem solid rgb(113, 255, 25);
  }
  
  .rule {
    font-size: 1.5rem;
    margin: 0.8rem auto;
    font-weight: 300;
  }
  
  .span-rule {
    font-size: 1.7rem;
  }
  
  @media screen and (max-width: 500px) {
    .productcare-container {
      width: 100%;
    }
  
    .care {
      width: 100%;
      margin: 0 auto;
    }
  
    .care-header {
      width: 100%;
      text-align: center;
      font-size: 1.5rem;
      padding-bottom: 1rem;
      margin-top: 1rem;
    }

    .rule {
        font-size: 1.2rem;
    }
  
    .span-rule {
      font-size: 1.3rem;
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
  