#bookingForm {
  position: fixed;
  top: 4.4rem;
  background-color: white;
  /* border: 0.1rem solid rgb(133, 255, 62); */
  border-radius: 0.3rem;
  padding: 2rem;
  padding-top: 0rem;
  padding-bottom: 2rem;
  width:95%;
  height: 85%;
  justify-content: center;
  left: 0;
  z-index: 1;
}

.sel {
  transition: 0.2s ease;
  opacity: 1;
}

.contactform-header-two {
  text-align: start;
}

.vehicle-info {
  margin: 0;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
}



#rs-model {
  width: 32%;
  font-size: 0.9rem;
  text-align: center;
  background-color: lightgrey;

}

#addons {
  background-color: lightgrey;
  text-align: center;

}
#shades {
  background-color: lightgrey;
  text-align: center;


}

#parts {
  background-color: lightgrey;
  text-align: center;
}

#estprice {
  background-color: lightgrey;
  text-align: center;
}

#vehicle {
  width: 35%;
  text-align: center;
}
#dealerName {
  width: 30%;
  text-align: center;
  background-color: lightgrey;
}

#dPrice {
  width: 25%;
  text-align: center;
  background-color: lightgrey;

}
#cartype {
  width: 30%;
  text-align: center;
  background-color: lightgrey;

}


#poNumber {
  width: 25%;
  align-items: center;
}
#vin {
  width: 25%;
  align-items: center;
}

.booking-header {
  margin: 0;
  color: rgb(113, 255, 25);
  font-size: 1.8rem;
}

.booking-two {
  font-size: 1rem;
}

.booking-close {
  margin: 0;
  font-size: 2rem;
  color: black;
}

.booking-close:hover {
  transition: 0.1s;
  color: rgb(113, 255, 25);
}

.close-hdr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}



@media (max-width: 500px)  {
  #bookingForm {
    top: 2rem;
left: -0.5rem;
height: 100%;
width: 90%;
  }

  .vehicle-info {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  #rs-model {
    width: auto;
    font-size: 0.9rem;
    text-align: center;
  }
  
  #vehicle {
    width: auto;
    text-align: center;
  }
  #dealerName {
    width: auto;
    text-align: center;
  }
  #poNumber {
    width: auto;
    text-align: center;
  }
  #dPrice {
    width: auto;
    text-align: center;
  }
  #cartype {
    width: auto;
    text-align: center;
  }
  #vin {
    width: auto;
    text-align: center;
  }

  #parts {
    width: auto;
    text-align: center;
  }

  #estprice {
    width: auto;
    text-align: center;
  }

  .booking-header {
    font-size: 1.5rem;
  }
}