.faq-container {
  position: relative;
    margin: 0 auto;
    width: 90%;
    justify-content: center;
    padding: 2rem;
    margin-bottom: 2rem;
    top: 4rem;
  }
  
  .faq-header {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 1rem;
    color: rgb(113, 255, 25);
    font-size: 2rem;
  }
  
  .questions-container {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    justify-content: center;
  }
  .question-container {
    width: 100%;
  }
  
  .faq-question {
    display: flex;
    gap: 0.5rem;
    color: black;
    font-size: 1.3rem;
    align-items: center;
    cursor: pointer;
    margin: 0;
    height: 2rem;
  }
  
  .faq-question:hover {
    transition: 0.3s;
    color: rgb(113, 255, 25)
  }
  
  .question-icon {
    cursor: pointer;
  }
  
  .question-icon:hover {
    transition: 0.2s;
    color: rgb(113, 255, 25)
  }
  
  .list-container {
    width: 90%;
    margin: 0 auto;
    align-self: center;
    display: flex;
    justify-content: center;
  }
  
  @media screen and (max-width: 500px) {
    .faq-header {
      margin: 0;
      width: 100%;
      font-size: 1.5rem;
     
    }
  
    .faq-container {
      padding-top: 1rem;
      top: 4.5rem;
      width: 80%;
      margin: 0 auto;
      justify-content: center;
    }
  
    .questions-container {
      margin-top: 1rem;
      width: 100%;
      gap: 1rem;
    }
  
    .faq-question {
      font-size: 0.7rem;
    }

    .qs {
      font-size: 1rem;
    }
  
    .question-icon {
      font-size: 0.7rem;
    }
  }
  