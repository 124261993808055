.simulator-tint-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  gap: 5rem;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 1rem 0rem;
}

.simulator-rightside {
  width: 50%;
}

.left-side {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tint-img {
  position: relative;
}

.part-tint {
  position: absolute;
  margin: 0 auto;
  width: 45%;
  opacity: 0;
  background: transparent;
  transition: 0.7s;
}

.part-active {
  opacity: 1;
}

.radio-buttons {
  margin-left: 50px;
}

.del-btn-tint {
  font-size: 2rem;
  margin-top: 0.3rem;
}

.radio-header {
  color: black;
  font-size: 17px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.shades-header {
  font-size: 25px;
  width: 40%;
}

.shades {
  display: flex;
  margin-bottom: 20px;
}

.glass-container {
  display: flex;
  justify-content: flex-end;
  margin-left: 50px;
}

.shade-number {
  cursor: pointer;
  font-size: 25px;
  background-color: rgb(231, 231, 228);
  margin-right: 0.7rem;
  padding: 0.3rem;
  border-radius: 0.2rem;
  min-width: 1.1rem;
  text-align: center;
}

.number-inactive:hover {
  transition: 0.2s;
  background-color: rgb(113, 255, 25);
}

.shade-clicked {
  transition: 0.5s;
  background-color: rgb(143, 253, 75);
  color: black;
}

.del-btn-tint:hover {
  background-color: white;
  animation: shake 1s ease-in-out infinite;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }

  75% {
    transform: rotate(-20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.simulator-img {
  height: 300px;
  padding-left: 100px;
}

.section-container {
  margin: auto;
}

.estimated-price {
  font-size: 20px;
  margin: 0px;
  color: rgb(128, 126, 124);
}

.note {
  margin: 0;
}

.price-total {
  background-color: rgb(113, 255, 25);
  border-radius: 0.3rem;
  width: 50%;
  text-align: center;
  color: black;
  padding: 5px 15px;
  font-size: 20px;
  margin-bottom: 10px;
}

.price-tint {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn-tint-simulator {
  width: 100%;
  background-color: white;
  border: 0.1rem solid rgb(113, 255, 25);
  color: rgb(65, 30, 78);
}

.btn-tint-simulator:hover {
  color: white;
}

@media screen and (max-width: 500px) {
  .glass-container {
    height: auto;
  }

  .opts-container {
    margin: 0 auto;
  }

  .parts-opts {
    width: 90%;
  }

  .part-tint {
    width: 90%;
  }

  .shades {
    width: 100%;
    height: auto;
    align-items: center;
    display: flex;
  }

  .glass-container {
    margin-left: 1rem;
    height: 2.3rem;
  }

  .shades-header {
    font-size: 0.8rem;
    text-align: start;
    margin: 0;
    width: 100%;
  }

  .shade-number {
    font-size: 1rem;
  }

  .note {
    width: 100%;
    font-size: 0.8rem;
  }

  .price-tint {
    align-items: center;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 1.2rem;
  }

  .price-total {
    font-size: 1.2rem;
    width: 100%;
  }

  .btn-tint-simulator {
    width: 100%;
    font-size: 1.2rem;
  }
  .del-btn-tint {
    font-size: 1.8rem;
  }
}
