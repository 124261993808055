.mobile-menu-container {
  transition: 0.5s;
  margin: 0 auto;
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
}

.mobile-link {
  margin: 0 auto;
  text-decoration: none;
  color: rgb(161, 161, 161);
  font-size: 2rem;
  font-weight: 600;
}

.mobile-link:hover {
  transition: 0.3s;
  color: rgb(113, 255, 25);
}

.mobile-link-container {
  margin: 0 auto;
  position: relative;
  top: 5rem;
  display: flex;
  padding: 1rem 0;
  padding-top: 4rem;
  padding-bottom: 4rem;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
  width: 80%;
  height: 45%;
  align-items: center;
  border-bottom: 0.1rem solid rgb(113, 255, 25);
  border-top: 0.1rem solid rgb(113, 255, 25);
}

.mobile-close-button {
  cursor: pointer;
  margin: 0;
  position: relative;
  width: 100%;
  color: rgb(161, 161, 161);
  font-size: 3rem;
  text-align: end;
  top: 2.5rem;
  left: 8.3rem;
}

.mobile-close-button:hover {
  transition: 0.3s;
  color: rgb(113, 255, 25);
}

.hide {
  transition: 0.5s;
  display: flex;
  right: 0;
}

.lower-nav {
  width: 80%;
  margin: 0 auto;
  color: white;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 1rem;
  font-size: 1.5rem;
  margin-top: 5.5rem;

}

.calus {
  text-decoration: none;
  font-weight: 400;
  color: rgb(113, 255, 25);
}

.calus:hover {
  color: rgb(113, 255, 25);
}

.calus:hover .phone-icon {
  transition: 0.3s;
  scale: 110%;
}

.phone-icon {
  font-size: 1.1rem;
}
