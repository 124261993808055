.about-container {
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding-top: 5rem;
 
}

.service-container-header {
  margin: 0;
}


.about-top-text {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.about-top-bold {
  margin-left: 0.3rem;
  font-weight: 800;
}

.about-bottom-text {
  color: rgb(78, 78, 78);
  margin: 0;
  font-size: 1rem;
  width: 90%;
  padding-left: 1rem;
  border-left: 0.1rem solid rgb(113, 255, 25);
  text-overflow:ellipsis;
  overflow: auto;
  
}

.about-paragraph {
  font-weight: 300;
  font-size: 1.3rem;
  margin: 0;
  padding: 0;
}


.ctc {
  width: 100%;
}

@media (min-width: 1000px) {
  .about-top-text {
    font-size: 1.6rem;
  }
  .about-bottom-text {
    font-size: 1.3rem;
    min-height: 10rem;
   overflow: visible;
   margin-bottom: 9rem;
    
   
  }
}
