.autotint-main-container {
  margin: 0 auto;
  margin-bottom: 9rem;
  position: relative;
  top: 6rem;
  width: 100%;
  font-family: "Lato", sans-serif;
}

.autotint-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  width: 90%;
}

.tint-header {
  margin: 0 auto;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.tint-description {
  margin: 0;
  font-size: 1rem;
  color: black;
  text-align: center;
}

.tint-description-lower {
  margin-bottom: 1rem;
}
.dealer-descr {
  font-size: 1.3rem;
  text-align: start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dealer-ben {
  font-size: 1.3rem;
}

.dealer-toper {
  text-align: start;
}

.tint-topper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

@media screen and (max-width: 500px) {
  .autotint-main-container {
    width: 100%;
    margin-bottom: 7rem;
  }

  .tint-description-lower {
    font-size: 1.2rem;
    text-align: center;
  }

  .autotint-header {
    font-size: 0.7rem;
  }

  .tint-topper {
    padding-bottom: 0;
  }

  .tint-header {
    font-size: 2.5rem;
    margin: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
}
