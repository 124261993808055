.sales-container {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10rem;
  position: relative;
  top: 7rem;
}

.header {
  margin: 0;
}
