.application-form-container {
  margin-top: 1rem;
}

.application-input {
  font-family: "Lato", sans-serif;
  border: 0.1rem solid rgb(187, 186, 186);
  border-radius: 0.15rem;
}

input:focus {
  background: transparent;
}

.application-input::placeholder {
  font-size: 0.8rem;
}

.application-input:focus {
  outline: rgb(133, 255, 62);
  border: 0.1rem solid rgb(113, 255, 25);
}

.job-form-container {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.job-description-container {
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
}

.resume-container {
  margin: 0;
  width: auto;
  height: 2rem;
  border-radius: 0.15rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.resume {
  margin: 0;
  width: 50%;
}
.resume-header {
  font-size: 0.6rem;
  width: 40%;
  display: flex;
  align-items: center;
  text-align: center;
}

.job-description {
  margin: 0 auto;
  margin-bottom: 1rem;
  text-align: center;
}

.user-notes {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  resize: none;
  height: 5rem;
  border: 0.1rem solid rgb(187, 186, 186);
  border-radius: 0.2rem;
}

.user-notes::placeholder {
  font-size: 0.9rem;
}

.user-notes:focus {
  outline: rgb(133, 255, 62);
  border: 0.1rem solid rgb(113, 255, 25);
}

.job-submit-btn {
  font-family: "Lato", sans-serif;
  cursor: pointer;
  border: none;
  border-radius: 0.2rem;
  width: 100%;
  font-size: 0.8rem;
  height: 2rem;
  margin: 0.3rem 0;
  background-color: white;
  border: 0.1rem solid rgb(187, 186, 186);
  color: black;
}

.job-submit-btn:hover {
  transition: 0.3s;
  border: none;
  background-color: rgb(113, 255, 25);
}

.job-submit-btn:focus {
  transition: 0.1s;
  background-color: rgb(113, 255, 25);
  outline: none;
}

.job-submit-btn:active {
  transition: 0.1s;
  background-color: rgb(113, 255, 25);
  border: none;
}

.job-header {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  color: rgb(148, 147, 147);
  cursor: pointer;

  padding-right: 1.5rem;
}

.job-header:hover {
  transition: 0.2s ease;
  color: rgb(41, 41, 41);
}

.apply-header {
  color: rgb(41, 41, 41);
}

.header-cont {
  margin: 0;
}

.responsibilites-list {
  opacity: 0;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-bottom: 1rem;
  margin-left: 0;
  width: auto;
  display: none;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.8rem;
  color: rgb(0, 0, 0);
}

.lol {
  transition: 0.3s ease;
  opacity: 100;
}

.header-cont {
  height: 2rem;
}

.header-cont {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.header-arrow {
  transition: 0.3s ease;
  transform: rotate(-90deg);
  color: rgb(148, 147, 147);
  margin: 0;
  position: relative;
  left: -1.5rem;
  font-size: 0.7rem;
}

.flipped {
  transition: 0.3s ease;
  transform: rotate(0deg);
  color: rgb(41, 41, 41);
}

.blacked {
  color: rgb(41, 41, 41);
}

#position {
  background-color: lightgray;
}

@media (min-width: 1000px) {
  .application-input {
    height: 2rem;
    font-size: 1rem;
  }

  .user-notes {
    height: 7rem;
  }

  .resume-container {
    margin: 0;
    justify-content: center;
    gap: 1rem;
  }
  .resume {
    width: 30%;
  }
  .resume-header {
    width: 25%;
    font-size: 1rem;
  }
}
