.services-dropdown-container {
    transition: 0.3s;
    background-color: black;
    position: absolute;
    top: 5rem;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 3rem;
    display: none;
}

.dropdown-active{
    display: flex;
    transition: 0.3s;
}

@media (max-width: 500px) {
    .services-dropdown-container {
      
    display: none;

    }

}