.error-window-container {
  margin: 0 auto;
  position: fixed;
  z-index: 10;
  border: 0.2rem solid rgb(113, 255, 25);
  border-radius: 0.5rem;
  background-color: white;
  width: 95%;
  left: 2%;
  height: 80%;
  top: 15%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.errors-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}

.error-header {
  margin: 0;
  border: none;
  width: 100%;
  font-size: 1.7rem;
  background-color: rgb(113, 255, 25);
  color: black;
}

.error-header-check {
  font-size: 1.5rem;
  color: black;
}
.errors-container {
  margin: 0;
  position: relative;
  bottom: 5%;
  color: black;
}

.field {
  font-size: 1.5rem;
}

.close-error {
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 1rem;
  width: 50%;
  background-color: white;
  border: 0.1rem solid rgb(192, 191, 191);
  border-radius: 0.2rem;
  font-size: 1rem;
  color: black;
}

.close-error:hover {
  transition: 0.3s;
  background-color: rgb(113, 255, 25);
}

.close-succ {
  cursor: pointer;
  margin: 0 auto;
  position: relative;
  bottom: 5%;
  width: 50%;
  background-color: white;
  border: 0.1rem solid rgb(192, 191, 191);
  border-radius: 0.2rem;
  font-size: 1rem;
  color: black;
}

.close-succ:hover {
  transition: 0.3s;
  background-color: rgb(113, 255, 25);
  border: 0.1rem solid rgb(113, 255, 25);

}


.close-error:hover {
  border: 0.1rem solid rgb(113, 255, 25);
}

.msg-container {
  margin: 0 auto;
  width: 90%;
}

.success-msg {
  font-size: 1.2rem;
  color: black;
}
