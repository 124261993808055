.infocard-container {
  margin: 0;
  width: 100%;
  position: relative;
  top: 4.45rem;
  margin-bottom: 7rem;
  background-color: white;
}

.model-nav-container {
  margin: 0 auto;
  position: relative;
  margin-top: 1rem;
  padding-top: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  border-top: 0.1rem solid rgb(202, 202, 202);
}

.model-name {
  font-size: 1rem;
  color: rgb(113, 255, 25);
}

.info-nav {
  display: flex;
  gap: 0.7rem;
}

.info-btn {
  cursor: pointer;
  font-size: 0.8rem;
  border: none;
  background-color: white;
}

.pics-container {
  margin: 0 auto;
  margin-top: 1rem;
  width: fit-content;
}
.allpics-container {
  margin: 0 auto;
  margin-top: 1rem;
  width: 90%;
  display: flex;
  justify-content: center;
  gap: 0.3rem;
}

.product-img {
  width: 2.5rem;
}

.main-product-img {
  margin: 0 auto;
  display: flex;
  max-width: 22rem;
  max-height: 18rem;
}

.product-features-container {
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.feat-left-side {
  margin: 0;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feats-container {
  margin: 0;
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
}

.clipboard {
  margin: 0 auto;
  font-size: 5rem;
  color: rgb(113, 255, 25);
}

.prd-feature {
  font-size: 1.1rem;
  gap: 1rem;
  font-weight: 400;
}

.about-product-container {
  margin: 0 auto;
  margin-top: 1rem;
  width: 90%;
  border-bottom: 0.1rem solid grey;
}

.prod-list {
  margin: 0;
  padding: 1rem;
  padding-left: 2rem;
}

.description-point {
  font-size: 0.8rem;
  text-align: left;
  font-weight: 400;
  margin: 0.5rem 0;
}

.quick-description {
  margin: 0 auto;
  font-weight: 400;
  font-size: 1.6rem;
  width: 100%;
  text-align: center;
}

.apple-google-stores-container {
  margin: 0 auto;
  margin-top: 2rem;
  width: 90%;
  display: flex;
  justify-content: space-between;
  justify-content: center;
}

.appstore {
  margin: 0;
  width: 60%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.app-header {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.action-buttons-container {
  margin: 0 auto;
  margin-top: 1.2rem;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.infobtn {
  margin: 0;
  cursor: pointer;
  font-size: 1.2rem;
  border: none;
  background-color: white;
  color: rgb(113, 255, 25);
}

.store-img {
  cursor: pointer;
  margin: 0;
  width: 40%;
}

.info-btn {
  color: rgb(153, 153, 153);
}

.info-btn:hover {
  transition: 0.3s;
  color: black;
}

.active-info {
  transition: 0.2s;
  color: black;
  background-color: rgb(113, 255, 25);
  border: none;
  border-radius: 0.2rem;
}

@media (min-width: 1000px) {
  .model-name {
    font-size: 1.5rem;
  }

  .info-btn {
    font-size: 1.2rem;
  }

  .description-point {
    font-size: 1.1rem;
    font-weight: 400;
    padding: 0.5rem;
  }

  .product-img {
    cursor: pointer;
  }

  .app-header {
    font-size: 1.5rem;
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .apple-google-stores-container {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .appstore {
    display: flex;
    justify-content: space-around;
    width: 50%;
  }

  .store-img {
    width: 25%;
  }
}
