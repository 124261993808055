.hidden {
  display: none;
}

.visible {
  display: block;
}

.about-section {
  margin: 0 auto;
  width: 90%;
  padding-top: 1rem;
  margin-bottom: 2rem;
}

.services-section {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  position: relative;
}

.hdr-tint {
  font-size: 1.8rem;
}

.hdr-ppf {
  font-size: 1.8rem;
}

html {
  scroll-behavior: smooth;
}



.about-container {
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding-top: 5rem;
  padding-bottom: 4rem;
 
}

.service-container-header {
  margin: 0;
}


.about-top-text {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.about-top-bold {
  margin-left: 0.3rem;
  font-weight: 800;
}

.about-bottom-text {
  color: rgb(78, 78, 78);
  margin: 0;
  font-size: 1rem;
  width: 90%;
  padding-left: 1rem;
  border-left: 0.1rem solid rgb(113, 255, 25);
  max-height: 15rem;
  text-overflow:ellipsis;
  overflow: auto;
  
}

.about-paragraph {
 margin: 0;
  font-size: 1.3rem;
}


.ctc {
  width: 100%;
}

@media (min-width: 1000px) {
  .about-top-text {
    font-size: 1.6rem;
  }
  .about-bottom-text {
    font-size: 1.3rem;
    min-height: 10rem;
   overflow: visible;
   margin-bottom: 9rem;
    
   
  }
  .hdr-tint {
    font-size: 3.4rem;
  }

  .hdr-ppf {
    font-size: 3.4rem;
  }
}

