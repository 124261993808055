.servicecard-container {
  margin: 0 auto;
  padding: 1rem 0;
  padding-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.1rem solid rgb(218, 218, 218);

}


.servicecard-left-side {
  margin: 0;
  width: 58%;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}


.service-link-container {
  margin: 0;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.2rem;
}

.service-name {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}





@media screen and (max-width: 500px) {
  .service-link {
    text-decoration: none;
    color: rgb(113, 255, 25);
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }



  .service-description {
    font-size: 0.7rem;
    font-weight: 300;
    text-align:left;
  }

  

  .servicecard-right-side {
    width: 40%;
    margin-top: 1rem;
    margin: 0;
  }



  .service-name {
    font-size: 1.6rem;
  }

  .service-img {
    width: 100%;
  }

  .service-btn {
    font-size: 1.2rem;
  }
}

@media (min-width: 1000px) {
  .servicecard-right-side {
    display: flex;
    justify-content: end;
  }
  .service-img {
    width: 70%;
  }

  .service-name {
    font-size: 2.7rem;
  }
  .service-description {
    font-size: 1.2rem;
    font-weight: 300;
    text-align:left;
  }

  .servicecard-left-side {
    display: flex;
    gap: 0.8rem;
    
  }
}
