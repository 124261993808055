.services-container {
  margin: 0 auto;
  position: relative;
  margin-bottom: 7rem;
  top: 5rem;
  width: 90%;
}

.service-description {
  margin: 0;
}

.service-container-header {
  margin: 0;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 400;
  color: rgb(113, 255, 25);
}

.our-services {
  font-weight: 800;
}

@media (min-width: 1000px) {
  .service-container-header {
    font-size: 4rem;
  }
}
