.navbar-container {
  margin: 0;
  width: 100%;
  position: absolute;
}
.navbar {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.6);
  align-items: center;
  /* border-bottom: 0.1rem solid rgb(133, 255, 62); */
  position: fixed;
  z-index: 2;

}

.serv-link {
  cursor: pointer;
}

.scrolled {
  transition: 0.2s ease-in;
  background-color: rgba(0, 0, 0, 100%);
}

.nav-link {
  margin: 0;
  height: 100%;
  text-decoration: none;
  color: white;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link:hover {
  transition: 0.3s;
  color: rgb(113, 255, 25);
}

.navbar-logo {
  color: white;
  cursor: pointer;
  margin: 0;
  /* font-size: 3.2rem; */
  height:3rem;
}

.border {
  border: 0.15rem solid rgb(113, 255, 25);
}

.navbar-logo:hover {
  transition: 0.2s ease-in;
  color: rgb(113, 255, 25);
}

.navbar-logo:active {
  transition: 0.1s;
  color: rgb(173, 255, 136);
}

#hamb-menu-icon {
  color: white;
  font-size: 3rem;
  display: none;
}

#hamb-menu-icon:hover {
  transition: 0.2s ease-in;
  color: rgb(113, 255, 25);
}

@media (max-width: 500px) {
  .nav-link {
    display: none;
  }

  #hamb-menu-icon {
    display: block;
    right: 3rem;
  }

  .hamb-menu-container {
    position: relative;
    right: 5rem;

  }

  .navbar {
    gap: 7rem;
    height: 4.5rem;
    justify-content: space-around;
  }
  .navbar-logo {
    height: 2.8rem;
    margin-left: 5rem;
  }

  
}

@media (min-width: 1000px) {
  .navbar {
    height: 5rem;
  }

  .navlinks-cont {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .nav-link {
    font-size: 1.1rem;
  }
}
