.video-container {
  margin: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.main-video {
  width: 100%;
}

@media (max-width: 500px) {
  .main-video {
    width: auto;
    height: 65vh;
  }
}
