.autotint-main-container {
  margin: 0 auto;
  margin-bottom: 9rem;
  position: relative;
  top: 6rem;
  width: 100%;
  font-family: "Lato", sans-serif;
}

.rs-serv-header {
}

.rs-topper-tint {
  margin-top: 1.5rem;
  display: flex;
  align-items:flex-start;
}

.rs-topper-left {
  width: 70%;

}

.rs-topper-right {
  display: flex;
  width: 30%;
 
}

.tnt-img {
  width: 97%;
}



.rs-description {
  font-size: 1.1rem;
}

.autotint-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  width: 90%;
}

.tint-header {
  margin: 0 auto;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.tint-description {
  margin: 0;
  font-size: 1rem;
  color: black;
  text-align: center;
}

.tint-topper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;

}

.tnt {
  text-align: center;
}



@media screen and (max-width: 500px) {
  .autotint-main-container {
    width: 100%;
    margin-bottom: 7rem;
  }
  .rs-topper-right {
    display: none;
  }
  .rs-topper-tint{
    width: 100%;
    margin-top: 1rem;
  }

  .rs-topper-left {
    width: 100%;
  }

  .rs-description {
    font-size: 0.8rem;
  }

  .tint-description-lower {
    font-size: 1.2rem;
    text-align: center;
  }

  .rs-serv-header {
    font-size: 1.2rem;
  }

  .autotint-header {
    font-size: 1rem;
  }

  .tint-topper {
    padding-bottom: 0;
  }

  .tint-header {
    font-size: 2.5rem;
    margin: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
}
