.main-dealer-container {
  width: 100%;
  margin: 0 auto;
  color: white;
  display: none;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  
}

.fin-link {
  display: block;
  margin: 0;
  margin-bottom: 0.75rem;
  color: black;
  text-align: center;
  font-size: 1.5rem;
  padding: 0.2rem;
  border: 0.1rem solid rgb(113, 255, 25);
  border-radius: 0.2rem;
}

.fin-link:hover {
  transition: 0.3s ease-in;
  background-color: rgb(113, 255, 25);
  color: black;
}




.ppfec {
  opacity: 1;
  display: flex;
  position: relative;
  top: 2rem;
  margin-top: 5rem;
 
}


.rs-cnt {
  margin: 0 auto;
  width: 95%;
}

.packs-header {
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: block;
  color: black;
  font-size: 2.2rem;
  text-align: center;
  width: 100%;
  background-color: rgb(113, 255, 25);
}



.ppf-packs {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content:center;
  gap: 2rem;
  width: 95%;
  margin-bottom: 2rem;

}

.rems-packs {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content:center;
  gap: 1rem;
  width: 95%;
  margin-bottom: 2rem;
}

.tint-packs {
  width: 95%;
  justify-content: center;
  gap: 2rem;
}

.tnt-pc{
  justify-content: center;
  gap: 2rem;
}

#dealer-ctn {
  position: relative;
  top: 2rem;
  margin-top: 5rem;
}

.btn-book {
  width: 100%;
  justify-content: center;
  /* display: none;
  opacity: 0; */
  margin: 1rem auto;
}

#dealer-name {
  width: 30%;
  font-size: 1.2rem;
  display: none;
  margin: 0;
}

.dealer-login {
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  width: 90%;
}

.btn-submitpw {
  cursor: pointer;
  width: 20%;
  color: black;
  background-color: white;
  border: 0.1rem solid grey;
  margin: 0;
  margin-left: 0.3rem;
  border: 0.1rem solid rgb(160, 160, 160) ;
  border-radius: 0.3rem;
  height: 2rem;
  
}

.btn-submitpw:hover {
  transition: 0.3s;
  background-color: black;
  border: 0.1rem solid rgb(113, 255, 25) ;
  background-color: rgb(113, 255, 25);
}

#logout_btn {
  display: none;
}

.input-pw {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

@keyframes fadeIn {
  50% {
    opacity: 30;
  }
  100% {
    opacity: 100;
  }
}

.visible {
  animation: 2s fadeIn;
  animation-fill-mode: forwards;
  display: flex;
}

.quick-links-dealer {
  margin: 0;
  align-items: center;
  width: 100%;
}

.ql-hdr-dealer {
  color: black;
  margin: 0;
  text-align: center;
}

.ql-dealer {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;

}

.quick-link-dealer {
  cursor: pointer;
  color: black;
  margin: 0;
  width: 12rem;
  font-size: 1rem;
  padding: 0.2rem;
  background-color: white;
  border: 0.1rem solid lightgray;
  border-radius: 0.3rem;
}

.quick-link-dealer:hover {
  transition: 0.3s;
  border: 0.1rem solid rgb(113, 255, 25);

}

.scrollUp {
  cursor: pointer;
  font-size: 3rem;
  position:fixed;
  color: rgb(151, 151, 151);
  opacity: 0;
  display: none;
  right: 0.5rem;
  top: 20%;
  
}

.scrollUp:hover {
  transition: 0.3s;
  color: rgb(113, 255, 25)
}

.scrolledNah {
  transition: 0.4s ease-in;
  opacity: 50%;
  display: block;
}


.scrollR {
  display: none;
}

.ql-rm-ct {
display: none;
margin: 0;
}

.ql-rm {
  color: black;
}

.rm-ql {
  width: auto;
  padding: 0 0.5rem
}

.packnah {
  max-width: 15rem;
height: 42rem;
}



@media screen and (max-width: 500px) {

  .packs-header {
    margin-bottom: 0;
  }

  .fin-link {
    display: block;
    text-align: center;
    font-size: 0.9em;
    padding: 0.2rem;
    border: 0.1rem solid rgb(113, 255, 25);
    background-color: rgb(113, 255, 25);
    border-radius: 0.2rem;
  }
  .ql-rm-ct {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
    }
    

  .scrollUp {
    margin: 0;
    background-color: black;
    cursor: pointer;
    font-size: 2rem;
    position:fixed;
    align-items: center;
    top: 110%;
    width: 100vw;
    left: 0rem;
    bottom: 0;
    padding-top: 0.5rem;
    display: none;
    opacity: 0;
    justify-content: center;
    gap: 0.2rem

  }

  .scrollR {
    display: block;
    margin: 0;
    margin-bottom: 0.2rem;
  }

  .scrolledNah {
    transition: 0.4s ease-in;
    opacity: 100%;
    display: flex;
    top: 94%;
  }

 
  


  #dealer-ctn {
    margin: 0 auto;
    width: 90vw;
    top: 2rem;
  margin-top: 5rem;
  }

  .dealer-login {
    margin: 0 auto;
    width: 95%;
    justify-content: space-around;

  }

  #dealer-name {
    font-size: 1rem;
    width: 50%;
  }

  .input-pw {
    width: 40%;
  }

  .btn-submitpw {
    width: 30%;
    font-size: 1rem;
  }

  .main-dealer-container {
    width: 100%;
    gap: 1rem;
    flex-direction: column;
  }

  .ppf-packs {
    align-items: center;
    width: 100%;
    gap: 1rem;
    flex-direction: column;
  }

  .packs-header {
    font-size: 1.7rem;
  }

  .btn-book {
    width: 70%;
  }

  .quick-link-dealer {
    font-size: 0.9rem;
  }

  .ql-rm-ct {
    width: 100%;
  }

  .ql-ppf {
    padding: 0;
    width: 4rem;
    margin: 0;
    cursor: pointer;
    font-size: 0.7rem;
    height: 1.5rem;
    background-color: white;
    border: 0.1rem solid rgb(187, 186, 186);
    border-radius: 0.2rem;
    color: black;
  }
  
  .ql-ppf:focus {
    background-color: rgb(113, 255, 25);
    border: 0.1rem solid transparent;
  }
  
  .ql-ppf:active {
    transition: 0.1s;
    background-color: rgb(113, 255, 25);
  }
 
}
