#simulator-main {
  position: relative;
  top: 4.5rem;
}

.req-pt {
  width: 55%;
}



.simulator-box {
  margin-bottom: 3rem;
  background-color: white;
  position: relative;
}

.simulator-selector {
  display: flex;
  margin-bottom: rem;
  width: 100%;
  height: auto;
  flex-direction: row;
  background-color: black;
  color: white;
  text-align: center;
  position: relative;
  z-index: 1;
  top: 0.5rem;
}

.decider {
  margin: 0 auto;
  display: flex;
  width: 90%;
  justify-content: center;
}

.simulator-tint-header {
  width: 50%;
  font-size: 30px;
  text-align: center;
  justify-content: center;
  padding: 17px;
  margin: 0;
}

.simulator-tint-header:hover {
  cursor: pointer;
  background-color: rgb(113, 255, 25);
  color: black;
}

.header-clicked {
  background-color: rgb(113, 255, 25);
  color: black;
}

.simulator-ppf-header:hover {
  cursor: pointer;
  background-color: rgb(113, 255, 25);
}

.simulator-ppf-header {
  font-size: 30px;
  width: 50%;
  text-align: center;
  padding: 17px;
  margin: 0;
}

.radio-header {
  font-size: 1.6rem;
  width: 100%;
}

#govneco {
  align-items: center;
  width: 75%;
}


.ebanoe {
  margin: 0;
  width: 20rem;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .simulator-selector {
    position: relative;
    z-index: 1;
    top: 0rem;
  }

  .req-pt {
    width: 100%;
    height: 2rem;
  }

  .radio-header {
    font-size: 1.2rem;
    width: 100%;
  }

  #govneco {
    width: 100%;
  }

  .simulator-box {
    position: relative;
    top: 20px;
    margin-bottom: 5rem;
  }

  .simulator-ppf-header {
    font-size: 0.8rem;
  }

  .simulator-tint-header {
    font-size: 0.8rem;
  }
}
