.power-wall-container {
  position: fixed;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  color: rgb(207, 207, 207);
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.power-logo-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.adren-logo {
  margin: 0;
  width: 60%;
}

.power-logo {
  margin-top: 2rem;
  cursor: pointer;
  font-size: 15rem;
  border-radius: 100%;
}

.power-logo-header {
  margin: 0 auto;
  color: rgb(117, 117, 117);
  font-size: 1.3rem;
  text-align: center;
}

@keyframes fadeInAndOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  70% {
    translate: 0rem;
  }
  95% {
    translate: 0 -100%;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes glowing {
  0% {
    opacity: 0.3;
    color: rgb(0, 161, 219);
  }
  9% {
    color: rgb(182, 182, 182);
  }
  10% {
    opacity: 0.5;
  }

  15% {
    opacity: 1;
  }

  20% {
    opacity: 0.3;
  }

  25% {
    opacity: 1;
  }

  30% {
    opacity: 0.3;
    color: rgb(184, 184, 184);
  }

  35% {
    opacity: 1;
  }

  49% {
    color: rgb(184, 184, 184);
  }

  50% {
    color: rgb(0, 161, 219);
  }
  55% {
    color: rgb(184, 184, 184);
  }
  100% {
    color: rgb(184, 184, 184);
  }
}

@keyframes fade {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes powerOn {
  0% {
    color: rgb(61, 61, 61);
  }
  50% {
    color: rgb(41, 198, 255);
  }

  100% {
    color: rgb(0, 161, 219);
  }
}

.element {
  display: block;
  animation: glowing 2.6s ease-in-out infinite;
}

.fade-in-and-out {
  animation: fadeInAndOut 2s ease-in-out;
}

.blinking {
  animation: blink 4s infinite;
}

.turned {
  animation: fade 0.5s ease-in;
}

.powered {
  animation: powerOn 2s ease-in-out;
}
