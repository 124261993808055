.text-on-video {
  margin: 0;
  width: 90%;
  height: auto;
  position: absolute;
  top: 7.5rem;
  left: 1rem;
}

.text-main {
  color: white;
}

.text-top {
  display: flex;
  flex-direction: column;
  font-size: 2.2rem;
  gap: 0.5rem;
  font-weight: 600;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 1rem black;
}

.text-bottom {
  color: white;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 200;
}

.button-link {
  font-size: 1.3rem;
  text-decoration: none;
  color: rgb(113, 255, 25);
}

.done-right {
  margin: 0;
  color: rgb(113, 255, 25);
  font-size: 1.7rem;
}

.hdr-ppf {
  margin: 0;
  font-size: 1.5rem;
}
.hdr-tint {
  margin: 0;
  font-size: 2rem;
}

.link-arrow {
  margin: 0;
  font-size: 1.6rem;
}

.button-link-container {
  height: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.button-link:hover {
  transition: 0.3s ease-in-out;
  color: rgb(113, 255, 25);
}

.text-main {
  font-size: 1.5rem;
}

@media (min-width: 1000px) {
  .text-top {
    font-size: 4rem;
    gap: 1rem;
  }

  .text-on-video {
    left: 3rem;
    top: 10rem;
  }

  .text-main {
    font-size: 2rem;
  }

  .hdr-ppf {
    margin: 0;
    font-size: 4rem;
  }
  .hdr-tint {
    margin: 0;
    font-size: 4rem;
  }

  .done-right {
    margin: 0;
    font-size: 4rem;
  }

  .text-on-video {
    top: 8rem;
  }
}
